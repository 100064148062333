<template>
  <Toast />
  <skeleton v-show="!isLoaded" />
  <no-permission
    v-if="!permission.status"
    :permission="permission.name"
    v-show="isLoaded" />
  <transition
    mode="out-in"
    enter-active-class="animate__animated animate__fadeInDown"
    leave-active-class="animate__animated animate__fadeOutUp">
    <Breadcrumb
      class="my-0"
      v-show="isLoaded"
      :home="breadcrumbHome"
      :model="breadcrumbItems" />
  </transition>

  <transition
    mode="out-in"
    enter-active-class="animate__animated animate__fadeInUp"
    leave-active-class="animate__animated animate__fadeOutDown">
    <div class="w-100 card" v-if="permission.status" v-show="isLoaded">
      <article class="d-flex justify-content-around align-items-center">
        <span class="_font-bold _text-medium">
          Calculadora de préstamos
        </span>
      </article>
      <p class="_subtitle _font-bold my-0"></p>
      <section class="layout-config-content mb-3">
        <form>
        <div class="row">

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Asociado:</label>
            <AutoCompleteSearchAsociados
              ref="autocompleteAsociados"
              class="w-100"
              v-on:return-resultado="onResultados" />
          </div>

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Linea Credito:</label>
            <AutocompleteSearchLineasCreditoComponent
              :IdLinea="dataRellenar.IdLineaCredito"
              :Id="'IdLineaCredito'"
              :Edicion='true'
              :NewLinea="dataRellenar.IdLineaCredito"
              v-on:return-resultado="onResultadosLinea"
              ref="autocompleteLineaCredito" />
          </div>

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Monto solicitado:</label>
            <InputNumber
              class="w-100"
              placeholder="Ingrese el monto solicitado:"
              :min="0"
              prefix="$"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="en-US"
              v-model="cooCalculoCuotas.MontoOtorgado" />
          </div>

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Tasa de interés anual</label>
            <InputNumber
              class="w-100"
              placeholder="Ingrese la tasa de interes anual"
              :min="0"
              :max="100"
              suffix="%"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="en-US"
              v-model="cooCalculoCuotas.TasaInteres" />
          </div>

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Numero de cuotas:</label>
            <InputNumber
              class="w-100"
              placeholder="Ingrese el numero de cuotas:"
              :min="1"
              v-model="cooCalculoCuotas.NumeroCuotas" />
          </div>

          <div class="col-sm-12 col-lg-6 mt-0 mb-0">
            <div class="field col">
              <label class="w-100 _text-small" for="name2">
                Forma de pago cuota
              </label>
              <div class="d-flex flex-row justify-content-start">
                <div class="field-radiobutton mb-0">
                  <RadioButton
                    id="option1"
                    name="option"
                    value="2"
                    v-model="cooCalculoCuotas.FormaPago" />
                  <label class="w-100 _text-small" for="option1"
                    >Por dia</label
                  >
                </div>
                <div class="field-radiobutton mb-0 ml-3">
                  <RadioButton
                    id="option1"
                    name="option"
                    value="1"
                    v-model="cooCalculoCuotas.FormaPago" />
                  <label class="w-100 _text-small" for="option1"
                    >Por mes</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Frecuencia de pago:</label>
            <InputNumber
              class="w-100"
              placeholder="Ingrese la frecuencia de pago:"
              :min="1"
              v-model="cooCalculoCuotas.Frecuencia" />
          </div>

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Cuota de aportacion:</label>
            <InputNumber
            class="w-100"
            placeholder="Ingrese la cuota de aportacion:"
            :min="0"
            prefix="$"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="en-US"
            v-model="cooCalculoCuotas.CuotaAportacion" />
          </div>

          <div class="col-sm-12 col-lg-6 hidden">
            <label class="w-100 _text-small">Cuota de manejo:</label>
            <InputNumber
              class="w-100"
              placeholder="Ingrese la cuota de manejo:"
              :min="0"
              prefix="$"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="en-US"
              v-model="cooCalculoCuotas.CuotaManejo" />
          </div>

          <div class="col-sm-12 col-lg-6 mb-2">
            <label class="w-100 _text-small">Cuota de ahorro:</label>
            <InputNumber
              class="w-100"
              placeholder="Ingrese la cuota de ahorro:"
              :min="0"
              prefix="$"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="en-US"
              v-model="cooCalculoCuotas.CuotaAhorro" />
              <Checkbox
              v-model="dataRellenar.AhorroManual"
              name="optionSeguro"
              :binary="true"
              :trueValue="1"
              :falseValue="0"
              class="mt-2"
              />
            <label class="ms-2 _text-small">Ingresar manualmente</label>
          </div>

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Valor de la cuota:</label>
            <InputText
              type="text"
              disabled
              class="w-100"
              style="font-weight: bold; color: red"
              v-model="dataRellenar.ValorCuota" />
          </div>

          

          <div class="col-sm-12 col-lg-6">
            <label class="w-100 _text-small">Fecha de inicio:</label>
              <CalendarCustom v-model="cooCalculoCuotas.FechaInicio" />
          </div>

          <div class="col-sm-12 col-lg-6 pt-4">
            <Checkbox
              v-model="cooCalculoCuotas.SeguroDeuda"
              name="optionSeguro"
              :binary="true"
              :trueValue="1"
              :falseValue="0"
              />
            <label class="ms-2 _text-small">¿Incluir seguro de deuda?</label>
          </div>
        </div>
      </form>

      <div class="row mt-8 mb-4" v-if="cooCalculoCuotas.NombreAsociado != ''">
        <div class="col-sm-12 col-md-4">
          <Button
            label="Imprimir tabla de amortización"
            @click="printTablaAmortizacion(cooCalculoCuotas)"
            icon="pi pi-print"
            class="p-button w-100" />
        </div>
        <div class="col-sm-12 col-md-4">
          <Button
            label="Imprimir hoja de tramite de crédito"
            @click="printHojaTramiteCredito(cooCalculoCuotas)"
            icon="pi pi-file-pdf"
            class="p-button-info w-100" />
        </div>
        <div class="col-sm-12 col-md-4">
          <Button
            label="Limpiar Campos"
            @click="resetInput()"
            icon="pi pi-trash"
            class="p-button-secondary w-100" />
        </div>
      </div>

      </section>
    </div>
  </transition>
</template>
<script>
import Skeleton from '@/components/Skeleton.vue';
import NoPermission from '@/components/NoPermission.vue';
import { verifyPermission } from '@/api/index';
import moment from 'moment'
import calculoPrestamos from '@/utils/Reportes/Prestamos/CalculoCuotas/CalculosPrestamosPDF.js';
import { tablaAmortizacion } from '@/utils/CalculosPrestamos'
import AutoCompleteSearchAsociados
  from '@/components/AutoCompleteComponents/AutoCompleteSearchAsociados.vue';
import AutocompleteSearchLineasCreditoComponent
  from '@/components/General/LineasCredito/AutocompleteSearchLineasCreditoComponent.vue';
import reporteTramiteCredito from '@/utils/Reportes/Prestamos/CalculoCuotas/TramiteCreditoPDF.js';
import CalendarCustom from '@/components/CalendarCustom.vue'

export default {
  components: {
    Skeleton,
    NoPermission,
    AutoCompleteSearchAsociados,
    AutocompleteSearchLineasCreditoComponent,
    CalendarCustom
  },
  data() {
    return {
      permission: {
        name: 'Visualizar calculadora prestamos',
        status: false,
      },
      isLoaded: false,
      isLoadedTable: false,
      breadcrumbHome: { icon: 'pi pi-home', to: '/' },
      breadcrumbItems: [
        { label: 'Calculadora de préstamos', to: '#' },
      ],
      active: false,
        cooCalculoCuotas: {
          CuotaAportacion: 5,
          SeguroDeuda: 1,
          FormaPago: "1",
          CuotaManejo: 0,
          FechaInicio: new Date(),
          NombreAsociado: ""
        },
        dataRellenar: {
          AhorroManual: 0,
          IdLineaCredito: 1
        },
        arrayCuotas: {},
    };
  },
  watch: {
			$route() {
				if (this.active) {
					this.active = false;
					this.unbindOutsideClickListener();
				}
			},
      cooCalculoCuotas: {
        handler(newValue, oldValue) {
          const tasaInteres = parseFloat(newValue.TasaInteres);
          const numeroCuotas = parseFloat(newValue.NumeroCuotas);
          const montoOtorgado = parseFloat(newValue.MontoOtorgado);
          const cuotaAportacion = parseFloat(newValue.CuotaAportacion) || 0;
          const cuotaManejo = parseFloat(newValue.CuotaManejo);
          const frecuenciaPago = parseFloat(newValue.Frecuencia);

          const TasaSeguro = 0.60
          let CuotaSeguro = 0.00
          let cuotaAhorro = 0;
          let valorFinal = '---';

          try {

            // Calculo de la cuota seguro
            if(newValue.SeguroDeuda == 1){
              CuotaSeguro = (montoOtorgado * TasaSeguro / 1000).toFixed(2)
              CuotaSeguro = (CuotaSeguro / 30 * 31).toFixed(2)

              if(newValue.FormaPago == 1){
                CuotaSeguro = (CuotaSeguro * frecuenciaPago).toFixed(2)
              }else{
                CuotaSeguro = (((montoOtorgado * TasaSeguro / 1000, 2).toFixed(2) / 30, 8).toFixed(2) * frecuenciaPago, 2).toFixed(2)
              }
            }else{
              CuotaSeguro = 0.00
            }

            CuotaSeguro = parseFloat(CuotaSeguro)
            this.cooCalculoCuotas.CuotaSeguro = CuotaSeguro

            // Calculo de la cuota de ahorro: (MontoAprobado * 3%) / NumeroCuotas
            if(this.dataRellenar.AhorroManual == 1){
              cuotaAhorro = newValue.CuotaAhorro
            }else{
              cuotaAhorro = (montoOtorgado * 0.03) / numeroCuotas
            }
            this.cooCalculoCuotas.CuotaAhorro = cuotaAhorro || 0

            // Calculo de la cuota mensual utilizando la fórmula de amortización francesa
            let interesFormula;
            if (newValue.FormaPago == 1) {
              // Frecuencia de pago mensual
              interesFormula = tasaInteres / 100 / (12 / frecuenciaPago);
            } else {
              // Frecuencia de pago diaria
              interesFormula = tasaInteres / 100 / (365 / frecuenciaPago);
            }
            const r1 = Math.pow(1 + interesFormula, numeroCuotas);
            const r2 = (montoOtorgado * interesFormula * r1) / (r1 - 1);
            const cuota_mes = r2;

            valorFinal =
              cuota_mes +
              (!isNaN(cuotaAportacion)
                ? cuotaAportacion
                : 0) +
              (!isNaN(cuotaAhorro) ? cuotaAhorro : 0) +
              (!isNaN(cuotaManejo) ? cuotaManejo : 0) +
              CuotaSeguro;
            valorFinal = parseFloat(valorFinal).toFixed(2);

            this.dataRellenar.ValorCuota = isNaN(valorFinal) ? 0 : valorFinal;
          } catch (error) {
            valorFinal = '---';
            this.dataRellenar.ValorCuota = isNaN(valorFinal) ? 0 : valorFinal;
          }
        },
        deep: true,
      },
		},
  methods: {
    async verifyPermissions() {
      this.isLoaded = false;
      try {
        const response = await verifyPermission([this.permission.name]);
        this.permission = response.content;
      } catch (error) {
        this.addNotification(
          'error',
          'Error al verificar permisos',
          error.message
        );
      }
      this.isLoaded = true;
    },
    printTablaAmortizacion(data){
        if(!this.validateData(data)){
          return
        }

        // Realizamos el proceso para la tabla de amortización
        try {
          const _data = {
            ...data,
            FechaInicio: moment(data.FechaInicio).format("DD/MM/YYYY")
          }
          // Realizamos el calculo para la tabla de amortizacion
          this.arrayCuotas = tablaAmortizacion(_data)
          // Print Plan de pagos
          this.arrayCuotas['cuotasPdf'].edadDelAsociado = data.Edad
          calculoPrestamos(this.arrayCuotas['cuotasPdf'])

        } catch (error) {
          this.addNotification(
            'error',
            'Error al obtener el calculo de cuota',
            error.message
          );
        }

      },

      printHojaTramiteCredito(data){
        if(!data.NombreAsociado){
          this.addNotification('warn', 'Error de validación',
          'El Asociado es requerido');
          return false;
        }
        if(!data.NombreLinea){
          this.addNotification('warn', 'Error de validación',
          'La línea de crédito es requerido');
          return false;
        }
        if(!data.MontoOtorgado){
          this.addNotification('warn', 'Error de validación',
          'El monto otorgado es requerido');
          return false;
        }
        if(!data.NumeroCuotas){
          this.addNotification('warn', 'Error de validación',
          'El numero de cuotas es requerido');
          return false;
        }

        let arrayCuotas = {
          nombreAsociado: data.NombreAsociado,
          telefono: data.Telefonos,
          lineaCredito: data.NombreLinea,
          montoOtorgado: data.MontoOtorgado,
          numeroCuotas: data.NumeroCuotas
        }
        reporteTramiteCredito(arrayCuotas)
      },

      // Reset the input
      resetInput(){
        this.cooCalculoCuotas = {
          CuotaAportacion: 5,
          SeguroDeuda: 1,
          FormaPago: "1",
          CuotaManejo:0,
          FechaInicio: new Date()
        }

        this.dataRellenar = {
          AhorroManual: 0,
          IdLineaCredito: "1"
        }

        this.$refs.autocompleteAsociados.limpiarInput()
        this.$refs.autocompleteLineaCredito.limpiarInput()
      },

      // get data of the associated
      onResultados(data){
        this.cooCalculoCuotas.NombreAsociado = data.Nombres+' '+data.Apellidos
        this.cooCalculoCuotas.Edad = moment().diff(data.FechaNacimiento, 'years')
        this.cooCalculoCuotas.Telefonos = data.Telefonos
      },

      // get data of the associated
      onResultadosLinea(id){
        const NameLinea = this.$refs.autocompleteLineaCredito.getNameLineaCredito()
        this.cooCalculoCuotas.NombreLinea = NameLinea
      },
			toggleConfigurator(event) {
				this.active = !this.active;
        this.$emit('status-changed-calculator', this.active);
				event.preventDefault();
			},
			hideConfigurator(event) {
				this.active = false;
        this.$emit('status-changed-calculator', this.active);
				this.unbindOutsideClickListener();
				event.preventDefault();
			},
			unbindOutsideClickListener() {
				if (this.outsideClickListener) {
					document.removeEventListener('click', this.outsideClickListener);
					this.outsideClickListener = null;
				}
			},
			isOutsideClicked(event) {
				return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
			},
      validateData(data) {
        if (!data.MontoOtorgado) {
          this.addNotification(
            'warn',
            'Error de validación',
            'El monto solicitado es requerido'
          );
          return false;
        }
        if (!data.TasaInteres) {
          this.addNotification(
            'warn',
            'Error de validación',
            'La tasa de interés es requerida'
          );
          return false;
        }
        if (!data.NumeroCuotas) {
          this.addNotification(
            'warn',
            'Error de validación',
            'El numero de cuotas es requerido'
          );
          return false;
        }
        if (!data.Frecuencia) {
          this.addNotification(
            'warn',
            'Error de validación',
            'La frecuencia de pago es requerida'
          );
          return false;
        }
        if (!data.FechaInicio) {
          this.addNotification(
            'warn',
            'Error de validación',
            'La Fecha de inicio es requerida'
          );
          return false;
        }
        return true;
      },
      addNotification(type, title, text) {
        this.$toast.add({
          severity: type,
          summary: title,
          detail: text,
          life: 3000,
        });
      },
  },
  mounted() {
    this.verifyPermissions();
  },
};
</script>
<style lang=""></style>

<template>
    <AutoComplete 
        v-model="this.IdLinea" 
        v-model:visible="display"
        :id="this.Id"
        :style="{ width: '100%' }"
        :suggestions="filteredBrands" 
        @item-select="selectBrand($event)" 
        @complete="searchBrand($event)" 
        placeholder="Busque una linea de credito..." 
        :dropdown="true" >
            <template #item="slotProps">
                <div>{{slotProps.item.IdLinea}}-{{slotProps.item.Nombre}}</div>
            </template>
    </AutoComplete>
</template>
<script>
import { findCooLineasCredito, getSpecificLineasCredito } from '@/api/index';
export default {
  props: ['IdLineas', 'Id', 'NombreAsociado', 'Edicion', 'NewLinea'],
  data() {
    return {
      filteredBrands: null,
      display: true,
      IdLinea: null,
      buscado: false,
      NameLinea: '',

    };
  },
  methods: {
    selectBrand(event) {
        this.NameLinea = event.value.Nombre
        this.IdLinea = event.value.IdLinea + ' - '+event.value.Nombre;
        this.$emit('return-resultado', event.value.IdLinea);
    },
    async searchBrand(event) {
      const LIMIT = 5 
      if (!event.query.trim().length) {
        const response = await findCooLineasCredito('a', LIMIT);
        this.filteredBrands = response.content;
      }
      else {
          const response = await findCooLineasCredito(event.query, LIMIT);
          this.filteredBrands = response.content;
      }
		},
    limpiarInput(){
      this.IdLinea = null
      this.NameLinea = ''
    },
    getNameLineaCredito(){
      return this.NameLinea
    },
    // Open confirmation delete dialog and set the id  to delete
    open() {
      this.display = true;
    },
    // Close confirmation delete dialog
    close() {
      this.display = false;
    },
    addNotification(type, title, text) {
      this.$toast.add({
        severity: type,
        summary: title,
        detail: text,
        life: 3000,
      });
    },
    async getSpecificLineasCredito(Cuenta) {
      this.isLoaded = false;
      try {
        const response = await getSpecificLineasCredito(this.NewLinea);
        let linea = `${response.content['IdLinea']} - ${response.content['Nombre']}`;
        this.IdLinea = linea;
        this.buscado = true;
        this.$emit('return-selected', linea);
        /*document.getElementById(this.Id).value = cuenta;
        document.getElementById(this.Id).dispatchEvent(new Event('input'));*/
      } catch (error) {
        this.addNotification(
          'error',
          'Error al obtener el linea de credito',
          error.message
        );
      }
      this.isLoaded = true;
    },
  },
  mounted() {
  },
  beforeUpdate () {
    if(this.Edicion == true && !this.buscado) {
      this.getSpecificLineasCredito(this.NewLinea);
    }
  },
};
</script>
<style lang=""></style>

import moment from "moment";

export function tablaAmortizacion(data) {
  try {

    let montoOtorgado = parseFloat(data.MontoOtorgado);
    let tasaInteres = parseFloat(data.TasaInteres);
    let numeroCuotas = data.NumeroCuotas;
    let fechaInicio = data.FechaInicio;
    let formaPago = parseInt(data.FormaPago);
    let frecuenciaPago = parseInt(data.Frecuencia);
    let cuotaAportacion = parseFloat(data.CuotaAportacion);
    let cuotaAhorro = parseFloat(data.CuotaAhorro);
    let cuotaSeguro = 0;
    let NumeroPeriodosAnio = 0
    let fechaProxima = null
    const TasaSeguro = 0.60;

    // Los siguientes datos pertenecen a la siguiente tabla de la base de datos:
    // select DiasPrestamo,MaximoAsegurablePrestamo From adm_Parametros;
    const DiasPrestamo = 365;
    let MaxPrestamo = 100000.00

    let NombreAsociado = (data.NombreAsociado != null)
      ? data.NombreAsociado : ''
    let NombreLinea = (data.NombreLinea != null)
      ? data.NombreLinea : ''
    let Telefonos = (data.Telefonos != null) ? data.Telefonos : ''
    const today = moment();
    let Edad = (data.FechaNacimiento != null) ? today.diff(data.FechaNacimiento, 'years') : 0

    // Declarar los arreglos
    let arrayCuotas = {}
    let arrayCuotasExcel = [];

    arrayCuotas = {
      montoOtorgado: montoOtorgado,
      numeroCuotas: (data.NumeroCuotas).toString(),
      tasaInteresAnual: data.TasaInteres,
      edadDelAsociado: Edad,
      formaPago: formaPago,
      fechaOtorgado: data.FechaInicio,
      nombreAsociado: NombreAsociado,
      lineaCredito: NombreLinea,
      telefono: Telefonos,
      cuotas: []
    }

    if (formaPago == 1) {
      NumeroPeriodosAnio = 12 / frecuenciaPago
    }else{
      NumeroPeriodosAnio =  DiasPrestamo / frecuenciaPago
    }

    let tasaPago = tasaInteres / 100 / NumeroPeriodosAnio
    let pagoCalculado = 0

    if(tasaPago == 0 ){
      pagoCalculado = (montoOtorgado / numeroCuotas).toFixed(2)
    }else{
      pagoCalculado = (montoOtorgado * (tasaPago * (Math.pow(1 + tasaPago, numeroCuotas) / (Math.pow(1 + tasaPago, numeroCuotas) - 1)))).toFixed(2);
    }

    if (montoOtorgado < MaxPrestamo){
      MaxPrestamo = montoOtorgado;
      cuotaSeguro = (MaxPrestamo * TasaSeguro / 1000).toFixed(2)
      cuotaSeguro = (parseFloat(cuotaSeguro) / 30 * 31).toFixed(2)

      if(formaPago == 1){
        // calcular el seguro diario cuando el primer mes tenga 31 días para efectos que el asociado no caiga en mora
        fechaProxima = moment(fechaInicio).add(frecuenciaPago, 'months').format('YYYY-MM-DD')
        if(moment(fechaProxima).diff(fechaInicio, 'days') > 30){
          cuotaSeguro = (parseFloat(cuotaSeguro) * frecuenciaPago).toFixed(2)
        }
      }else{
        cuotaSeguro = (((MaxPrestamo * TasaSeguro / 1000).toFixed(2) / 30).toFixed(8) * frecuenciaPago).toFixed(2)
      }
    }


    let totalSeguro = 0;
    let totalAportacion = 0;
    let totalAhorro = 0;
    let totalIntereses = 0;
    let totalAbonoCapital = 0;
    let DiasCalculados = 0;
    let fechaAnterior = fechaInicio
    let importeInteres = 0
    let saldoAnterior = montoOtorgado
    let importeCuota = 0
    let importeCapital = 0
    let ImporteSaldo = 0
    let fechaPago = moment(fechaInicio);
    for (let index = 1; index <= numeroCuotas; index++) {

      if(formaPago == 1){
        fechaPago = moment(fechaInicio).add((frecuenciaPago * index), 'months').format('YYYY-MM-DD')
      }else{
        fechaPago = moment(fechaInicio).add((frecuenciaPago * index), 'days').format('YYYY-MM-DD')
      }

      DiasCalculados = moment(fechaPago).diff(fechaAnterior, 'days')

      importeInteres = (saldoAnterior * (tasaInteres / 100) * DiasCalculados / DiasPrestamo).toFixed(2)
      importeCuota = parseFloat(pagoCalculado) + parseFloat(cuotaSeguro) + cuotaAportacion + cuotaAhorro

      if(index == numeroCuotas){
        importeCuota = saldoAnterior + parseFloat(importeInteres) + parseFloat(cuotaSeguro) + cuotaAportacion + cuotaAhorro
      }

      importeCapital = importeCuota - (parseFloat(importeInteres) + parseFloat(cuotaSeguro) + cuotaAportacion + cuotaAhorro)
      ImporteSaldo = saldoAnterior - importeCapital

      totalSeguro += parseFloat(cuotaSeguro);
      totalAportacion += parseFloat(cuotaAportacion);
      totalAhorro += parseFloat(cuotaAhorro);
      totalIntereses += parseFloat(importeInteres);
      totalAbonoCapital += parseFloat(importeCapital);
      // Se agregan los datos para cada mes
      let arrayNew = {
        numero: index,
        fecha: fechaPago,
        interesMensual: (importeInteres),
        cuotaTotal: importeCuota,
        abonoACapital: importeCapital,
        saldoPrestamo: ImporteSaldo,
        aportacion: cuotaAportacion,
        ahorro: cuotaAhorro,
        seguro: cuotaSeguro,
      }

      // Se agregan los datos para excel
      let arrayNewExcel = {
        'NumeroCuota': (index + 1),
        'FechaPago': fechaPago,
        'ImporteCuota': importeCuota,
        'ImporteCuotaSeguro': cuotaSeguro,
        'ImporteCuotaAportacion': cuotaAportacion,
        'ImporteCuotaAhorro': cuotaAhorro,
        'ImporteCuotaManejo': '0.00',
        'ImporteInteres': importeInteres,
        'ImporteCapital': importeCapital,
        'ImporteSaldo': ImporteSaldo,
      }

      // Add cuotas mensual
      arrayCuotas.cuotas.push(arrayNew);
      arrayCuotasExcel.push(arrayNewExcel);

      fechaAnterior = fechaPago
      saldoAnterior = ImporteSaldo
    }

    let arrayNewOut = {
      numero: '',
      fecha: '',
      interesMensual: totalIntereses.toFixed(2),
      cuotaTotal: 'TOTALES',
      abonoACapital: totalAbonoCapital.toFixed(2),
      saldoPrestamo: '',
      aportacion: totalAportacion.toFixed(2),
      ahorro: totalAhorro.toFixed(2),
      seguro: totalSeguro.toFixed(2),
    }

    let arrayNewOutExcel = {
      'NumeroCuota': '',
      'FechaPago': '',
      'ImporteCuota': 'TOTALES',
      'ImporteCuotaSeguro': totalSeguro.toFixed(2),
      'ImporteCuotaAportacion': totalAportacion.toFixed(2),
      'ImporteCuotaAhorro': totalAhorro.toFixed(2),
      'ImporteCuotaManejo': '0.00',
      'ImporteInteres': totalIntereses.toFixed(2),
      'ImporteCapital': totalAbonoCapital.toFixed(2),
      'ImporteSaldo': '',
    }

    arrayCuotas.cuotas.push(arrayNewOut);
    arrayCuotasExcel.push(arrayNewOutExcel);

    // Add array in array for excel and pdf
    let ArrayCuotas = {
      cuotasPdf: arrayCuotas,
      cuotasExcel: arrayCuotasExcel
    }

    return ArrayCuotas
  } catch (error) {
    console.error(error.message);
    return
  }
}
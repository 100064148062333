const _TITLE = ' - Acoasmi SV';
const routesReportesAsociados = [{
        path: 'asociados/reportes/listado-aspirantes',
        name: 'ReporteListadoAspirantesAsociados',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteListadoAspirantesAsociadosView.vue'),
        meta: {
            title: 'Reporte de aspirantes' + _TITLE
        }
    },{
        path: 'asociados/reportes/asociados-activos',
        name: 'ReporteAsociadosActivos',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteAsociadosActivosView.vue'),
        meta: {
            title: 'Reporte de asociados activos' + _TITLE
        }
    },{
        path: 'asociados/reportes/asociados-inactivos',
        name: 'ReporteAsociadosInactivos',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteAsociadosInactivosView.vue'),
        meta: {
            title: 'Reporte de asociados inactivos' + _TITLE
        }
    },{
        path: 'asociados/reportes/listado-asamblea',
        name: 'ReporteListadoAsociadosAsamblea',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteListadoAsambleaAsociadosView.vue'),
        meta: {
            title: 'Reporte de listado de asamblea' + _TITLE
        }
    },{
        path: 'asociados/reportes/asociados-genero',
        name: 'ReporteAsociadosGenero',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteAsociadosGeneroView.vue'),
        meta: {
            title: 'Reporte de asociados por genero' + _TITLE
        }
    },{
        path: 'asociados/reportes/consulta-general',
        name: 'ReporteConsultaGeneralAsociados',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteConsultaGeneralAsociadosView.vue'),
        meta: {
            title: 'Reporte de consulta generla de asociados' + _TITLE
        }
    },{
        path: 'asociados/reportes/listado-asociados-ingresados',
        name: 'ReporteListadoAsociadosIngresados',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteListadoAsociadosIngresadosView.vue'),
        meta: {
            title: 'Reporte de listado de asociados ingresados' + _TITLE
        }
    },{
        path: 'asociados/reportes/listado-asociados-clasificacion',
        name: 'ReporteListadoClasificacionAsociados',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteListadoClasificacionAsociadosView.vue'),
        meta: {
            title: 'Reporte de listado de asociados clasificacion' + _TITLE
        }
    },{
        path: 'asociados/reportes/asociados-retirados',
        name: 'ReporteAsociadosRetirados',
        component: () =>
            import ('@/views/Asociados/Reportes/ReporteAsociadosRetiradosView.vue'),
        meta: {
            title: 'Reporte de asociados retirados' + _TITLE
        }
    },
];

export { routesReportesAsociados };
import jsPDF from 'jspdf';
import moment from 'moment';
import autoTable from 'jspdf-autotable';
import { getConfigDefaultLogo } from '@/api/index';

let _X = 0;
let _Y = 0;
let _PageHeight = 0;
let _PageWidth = 0;

export default async function calculoPrestamos(data) {
  const doc = new jsPDF(
    {
      orientation: 'portrait',
      format: 'letter',
      units: 'mm'
    }
  );

  doc.setProperties({
      title:  'CalculosPrestamos'
  })
  const logo = new Image();
  try {
    const responseLogo = await getConfigDefaultLogo();
    const blob = new Blob([responseLogo], {
        type: 'image/png',
    });
    logo.src = URL.createObjectURL(blob);
  } catch (error) {
      return;
  }

  Config(doc);
  // Header(doc, data);
  doc.setFontSize(5);
  tablaCalculoPrestamos(doc, data, logo);
  addPageNumber(doc);
  //tablaFooter(doc,data);

  doc.output('dataurlnewwindow', {filename: 'CalculosPrestamos'});
}

function Config(doc) {
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(9);
  _PageHeight = Math.round(doc.internal.pageSize.height);
  _PageWidth = Math.round(doc.internal.pageSize.width);
}

function Header(doc, data) {
  _X = 10;
  _Y = 10;
  /* Adding the date and time to the top right corner of the page. */
  const dateTime = moment().format('DD/MM/YYYY hh:mm:ss');
  doc.setFontSize(7);
  doc.text(dateTime, Math.round(_PageWidth - dateTime.length), _Y, 'center');
  doc.setFontSize(13);
  doc.setFont('helvetica', 'bold');
  doc.text('ACOASMI DE R.L.', Math.round(_PageWidth / 2), _Y, 'center');
  _Y += 6;
  doc.setFontSize(11);
  doc.text(
    'CALCULO DE CUOTAS',
    Math.round(_PageWidth / 2),
    _Y,
    'center'
  );
  _Y += 10;
  doc.setFontSize(8);
  doc.setFont('helvetica', 'bold');
  doc.text('Monto Otorgado: ', 40, _Y, 'right');
  doc.setFont('helvetica', 'normal');
  doc.text(new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(data.montoOtorgado), 60, _Y, 'right');
  doc.setFont('helvetica', 'bold');
  doc.text('Forma de pago: ', 125, _Y, 'right');
  doc.setFont('helvetica', 'normal');
  doc.text(data.formaPago == 2 ? data.numeroCuotas+' cuotas diarias' : data.numeroCuotas+' cuotas mensuales', 127, _Y, 'left');
  _Y += 5;
  doc.setFont('helvetica', 'bold');
  doc.text('Numero de cuotas: ', 40, _Y, 'right');
  doc.setFont('helvetica', 'normal');
  doc.text(data.numeroCuotas, 60, _Y, 'right');
  doc.setFont('helvetica', 'bold');
  doc.text('Fecha de otorgado: ', 125, _Y, 'right');
  doc.setFont('helvetica', 'normal');
  doc.text(data.fechaOtorgado, 127, _Y, 'left');
  _Y += 5;
  doc.setFont('helvetica', 'bold');
  doc.text('Tasa de interes anual: ', 40, _Y, 'right');
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.tasaInteresAnual}%`, 60, _Y, 'right');
  doc.setFont('helvetica', 'bold');
  doc.text('Nombre del asociado: ', 125, _Y, 'right');
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.nombreAsociado}`, 127, _Y, 'left');
  _Y += 5;
  doc.setFont('helvetica', 'bold');
  doc.text('Edad del asociado: ', 40, _Y, 'right');
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.edadDelAsociado}`, 60, _Y, 'right');
  doc.setFont('helvetica', 'bold');
  doc.text('Linea de credito: ', 125, _Y, 'right');
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.lineaCredito}`, 127, _Y, 'left');
  _Y += 5;
  doc.setFont('helvetica', 'bold');
  doc.text('Credito rotativo ', 40, _Y, 'right');

  //doc.setFontSize(9);
  //doc.setFont('helvetica', 'normal');
  //const dateTimeHastaFecha = moment(data.HastaFecha).format('LL');
  //doc.text(dateTimeHastaFecha, Math.round(_PageWidth / 2), _Y, 'center');
  //-------------------------
  _Y += 4;
  /*  doc.text(
    'SUCURSAL: ' + data.NombreSucursal,
    Math.round(_PageWidth / 2),
    _Y,
    'center'
  );*/
  //-------------------------
  _Y += 2;
  doc.text(
    '______________________________________________________________________________________________________________',
    _PageWidth / 2,
    _Y,
    'center'
  );

  _Y += 10;

}

function tablaCalculoPrestamos(doc, data, logo) {
  //javascript
  var columns = [
    { title: 'No.', dataKey: 'contador' },
    { title: 'Fecha', dataKey: 'fecha' },
    { title: 'Cuota total', dataKey: 'cuotaTotal' },
    { title: 'Seguro', dataKey: 'seguro' },
    { title: 'Aportacion', dataKey: 'aportacion' },
    { title: 'Ahorro', dataKey: 'ahorro' },
    { title: 'Seg. de credito', dataKey: 'seguroCredito' },
    { title: 'Intereses', dataKey: 'interesMensual' },
    { title: 'ABONO A CAPITAL', dataKey: 'abonoACapital' },
    { title: 'SALDO DEL PRESTAMO', dataKey: 'saldoPrestamo' },
  ];
  var rows = [];

  data.cuotas.forEach(element => {
    rows.push({
      contador: element.numero,
      fecha: element.fecha,
      cuotaTotal: !isNaN(element.cuotaTotal) ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(element.cuotaTotal) : element.cuotaTotal,
      seguro:  new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(element.seguro),
      aportacion:  new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(element.aportacion),
      ahorro:  new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(element.ahorro),
      seguroCredito: '',
      interesMensual:  new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(element.interesMensual),
      abonoACapital:  new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(element.abonoACapital),
      saldoPrestamo:  new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(element.saldoPrestamo),
      /*nombre: element.NombreAsociado,
      saldo: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(element.TotalAportaciones),*/
    });
  });

  doc.autoTable(columns, rows, {
    startY: 60,
    headStyles: { halign: 'right', fontSize: 8 },
    columnStyles: {
      0: { halign: 'right', fontSize: 8 },
      1: { halign: 'right', fontSize: 8 },
      2: { halign: 'right', fontSize: 8 },
      3: { halign: 'right', fontSize: 8 },
      4: { halign: 'right', fontSize: 8 },
      5: { halign: 'right', fontSize: 8 },
      6: { halign: 'right', fontSize: 8 },
      7: { halign: 'right', fontSize: 8 },
      8: { halign: 'right', fontSize: 8 },
      9: { halign: 'right', fontSize: 8 },
    },
    theme: 'plain',
    margin: { top: 60 },
    beforePageContent: function () {
      Header(doc, data);
      doc.addImage(logo, 'PNG', 10, 1, 25, 17, 80);
    },
  });
}
function addPageNumber(doc) {
  const pageCount = doc.internal.getNumberOfPages(); //was doc.internal.getNumberOfPages();
  // For each page, print the page number and the total pages
  for (let i = 1; i <= pageCount; i++) {
    doc.setFontSize(6);
    // Go to page i
    doc.setPage(i);
    doc.text(
      'Página ' + String(i) + ' de ' + String(pageCount),
      doc.internal.pageSize.getWidth() - 30,
      5
    ); //data.settings.margin.left if you want it on the left
  }
}

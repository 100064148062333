export function getToken() {
    return new Promise((resolve) => {
        resolve(localStorage.getItem("jwt"));
    });
}

export function setToken(token) {
    return new Promise((resolve) => {
        localStorage.setItem("jwt", token);
        resolve();
    });
}

export function removeToken() {
    return new Promise((resolve) => {
        localStorage.removeItem("jwt");
        localStorage.removeItem('id_usuario');
        localStorage.removeItem('tema');
        localStorage.removeItem('escala');
        resolve();
    });
}

export function verifyExistingToken() {
    return new Promise((resolve) => {
        if (localStorage.getItem("jwt")) {
            resolve(true);
        } else {
            resolve(false);
        }
    });
}
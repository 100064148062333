import jsPDF from 'jspdf';
import moment from 'moment';
export default async function reporteTramiteCredito(data) {
  const doc = new jsPDF(
    {
      orientation: 'portrait',
      format: 'letter',
      units: 'mm'
    }
  );

  doc.setProperties({
      title:  'TramiteCredito'
  })
  const logo = new Image();

  try {
    const responseLogo = await getConfigDefaultLogo();
    const blob = new Blob([responseLogo], {
      type: 'image/png',
    });
    logo.src = URL.createObjectURL(blob);
  } catch (error) {
    logo.src = '../images/logo.png';
  }

  doc.setFont('helvetica', 'bold');
  doc.text('ACOASMI EL ROBLE DE RL', 70, 25);
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(11);

  doc.text('Tecoluca, ' + moment().format('LL'), 81, 30);
  doc.text('NOMBRE DEL ASOCIADO/A: ' + data.nombreAsociado, 20, 55, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.text('Telefonos: ' + data.telefono, 20, 60, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.text('1. - Linea de Credito a solicitar: ' + data.lineaCredito, 20, 70, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.setFont('helvetica', 'normal');
  doc.text('Monto. ' +new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(data.montoOtorgado), 30, 75, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.text('Plazo en meses: '+ data.numeroCuotas+ ' cuotas', 80, 75, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.setFont('helvetica', 'bold');
  doc.text('2. - DOCUMENTOS QUE EL SOLICITANTE DEBERA PRESENTAR', 20, 85, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.text('A - PERSONA NATURAL', 20, 90, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.setFont('helvetica', 'Normal');
  doc.text('Fotocopia del DUI a 140 %', 35, 95, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 92, 3, 3);
  doc.text('Constancia de sueldo, no mayor de 30 días.', 35, 99, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 96, 3, 3);
  doc.text('Copias de escritura', 35, 103, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 100, 3, 3);
  doc.text('Copias de poderes especiales', 35, 107, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 104, 3, 3);
  doc.text('Certificación extractada, no mayor de 30 días.', 35, 111, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 108, 3, 3);
  doc.text('Anotación preventiva', 35, 115, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 112, 3, 3);
  doc.text('Presupuesto de inversión e inventarios', 35, 119, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 116, 3, 3);
  doc.text('Referencias bancarias y comerciales', 35, 123, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 120, 3, 3);
  doc.text(
    'Recibos cancelados de servicio de agua, energía eléctrica, teléfono.',
    35,
    127,
    { align: 'justify', lineHeightFactor: 1.5, maxWidth: 190 }
  );
  doc.rect(30, 124, 3, 3);
  doc.text(
    'Si recibe remesas, copias de los últimos 3 recibos de remesas.',
    35,
    131,
    { align: 'justify', lineHeightFactor: 1.5, maxWidth: 190 }
  );
  doc.rect(30, 128, 3, 3);
  doc.text(
    'Solvencia fiscal, si el crédito es mayor de $ 30,000 USD y municipal si es bien urbano.',
    35,
    135,
    { align: 'justify', lineHeightFactor: 1.5, maxWidth: 190 }
  );
  doc.rect(30, 132, 3, 3);
  doc.setFont('helvetica', 'bold');
  doc.text('B - PERSONA JURIDICA', 20, 140, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.setFont('helvetica', 'Normal');
  doc.text('Personería jurídica', 35, 145, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 142, 3, 3);
  doc.text('Credenciales vigentes', 35, 149, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 146, 3, 3);
  doc.text('Estados financieros auditados', 35, 153, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 150, 3, 3);
  doc.text('Membresía', 35, 157, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 154, 3, 3);
  doc.text('Punto de acta de solicitud de crédito.', 35, 161, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 158, 3, 3);
  doc.text('NIT y registro de IVA de la empresa', 35, 165, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 162, 3, 3);
  doc.setFont('helvetica', 'bold');
  doc.text('3. - DOCUMENTOS DECODEUDORES Y/O FIADORES', 20, 170, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.setFont('helvetica', 'normal');
  doc.text('Fotocopia del DUI a 140 %', 35, 175, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 172, 3, 3);
  doc.text('Constancia de sueldo, no mayor de 30 días.', 35, 179, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 176, 3, 3);
  doc.text('Copias de escritura', 35, 183, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 180, 3, 3);
  doc.text('Copias de poderes especiales', 35, 187, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 184, 3, 3);
  doc.text('Certificación extractada, no mayor de 30 días.', 35, 191, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 188, 3, 3);
  doc.text('Anotación preventiva', 35, 195, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 192, 3, 3);
  doc.text('Referencias bancarias y comerciales', 35, 199, {
    align: 'justify',
    lineHeightFactor: 1.5,
    maxWidth: 190,
  });
  doc.rect(30, 196, 3, 3);
  doc.text(
    'Recibos cancelados de servicio de agua, energía eléctrica, teléfono.',
    35,
    203,
    {
      align: 'justify',
      lineHeightFactor: 1.5,
      maxWidth: 190,
    }
  );
  doc.rect(30, 200, 3, 3);
  doc.text(
    'Si recibe remesas, copias de los últimos 3 recibos de remesas.',
    35,
    207,
    {
      align: 'justify',
      lineHeightFactor: 1.5,
      maxWidth: 190,
    }
  );
  doc.rect(30, 204, 3, 3);
  doc.text(
    'Solvencia fiscal, si el crédito es mayor de $ 15,000 USD.',
    35,
    211,
    {
      align: 'justify',
      lineHeightFactor: 1.5,
      maxWidth: 190,
    }
  );

  doc.rect(30, 208, 3, 3);
  doc.text(
    'Favor de añadir croquis de ubicación en la parte trasera de la hoja',
    30,
    220,
    {
      align: 'justify',
      lineHeightFactor: 1.5,
      maxWidth: 190,
    }
  );
  doc.addImage(logo, 'PNG', 10, 10, 30, 25, 80);

  doc.autoPrint();
  doc.output('dataurlnewwindow', {filename: 'TramiteCredito'});
}

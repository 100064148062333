<template>
  <div>
    <Calendar
      v-bind="$attrs"
      :manualInput="true"
      :showIcon="true"
      :showButtonBar="true"
      class="w-100"
      dateFormat="dd-mm-yy"
      placeholder="DD-MM-YYYY"
      v-model="internalDate"
      @input="applyMask"
      @blur="onBlur"
      ref="calendarInput"
    />
  </div>
</template>

<script>
import moment from "moment"

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalDate: this.modelValue,
      lastKeyWasDigit: false
    };
  },
  watch: {
    modelValue(newValue) {
      this.internalDate = newValue;
    }
  },
  mounted() {
    // Add the keydown event listener to the input element after the component is mounted
    this.$refs.calendarInput.$el.querySelector('input').addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    this.$refs.calendarInput.$el.querySelector('input').removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    handleKeydown(event) {
      const key = event.key;
      this.lastKeyWasDigit = /\d/.test(key);
    },
    onBlur(event) {
      const data = {
        target: {
          value: event.value
        }
      }
      this.applyMask(data)
    },
    applyMask(event) {
      const inputElement = event.target;
      let inputValue = inputElement.value;
      let date = inputValue.replace(/\D/g, '');

      // Limit the length of the last part to 4 digits
      if (date.length > 8) {
        date = date.substring(0, 8);
      }

      const maskedDate = date
        .replace(/(\d{2})(\d)/, '$1-$2')
        .replace(/(\d{2})(\d)/, '$1-$2');

      inputElement.value = maskedDate;

      if (this.isValidDate(maskedDate)) {
        this.internalDate = maskedDate;
        const formatedDate = moment(maskedDate, "DD-MM-YYYY").format("YYYY-MM-DDTHH:mm")
        const newDate = new Date(formatedDate)
        this.$emit('update:modelValue', newDate);
      }

      if (this.lastKeyWasDigit) {
        // Set cursor to the end of the input only if the last key was a digit
        this.$nextTick(() => {
          if (inputElement.setSelectionRange) {
            inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
          }
          
        });
      }
    },
    isValidDate(dateString) {
      const dateParts = dateString.split('-');
      if (dateParts.length !== 3) return false;

      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);

      if (isNaN(day) || isNaN(month) || isNaN(year)) return false;
      if (day < 1 || day > 31) return false;
      if (month < 1 || month > 12) return false;

      // Additional checks for days in each month can be added here if needed
      return dateString.match(/^\d{2}-\d{2}-\d{4}$/) !== null;
    }
  }
};
</script>

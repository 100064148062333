<template>
    <AutoComplete
      v-model="this.NombreAsociado"
      v-model:visible="display"
      :style="{ width: '100%' }"
      :suggestions="filteredBrands"
      @item-select="selectBrand($event)"
      @complete="searchBrand($event)"
      placeholder="Busque un asociado..."
      dropdown
      emptySearchMessage="No se encontraron registros" >
        <template #item="slotProps">
          <div>{{ slotProps.item.Numero }} | {{slotProps.item.Nombres }} {{slotProps.item.Apellidos }} | {{slotProps.item.NumeroDocumento }}</div>
        </template>
    </AutoComplete>
  </template>
  <script>
  import { findCooAsociados, getSpecificCooAsociados } from '@/api/index';
  export default {
    data() {
      return {
        filteredBrands: null,
        display: true,
        NombreAsociado: null,
      };
    },
    methods: {
      selectBrand(event) {
        this.NombreAsociado = `${event.value.Numero} - ${event.value.Nombres} ${event.value.Apellidos} (${event.value.NumeroDocumento})`
        this.$emit('return-resultado', event.value);
      },
      async searchBrand(event) {
        const LIMIT = 5;
        if (!event.query.trim().length) {
          const response = await findCooAsociados('a', LIMIT);

          this.filteredBrands = response.content;
        }
        else {
            const response = await findCooAsociados(event.query, LIMIT);
            this.filteredBrands = response.content;
        }
      },

      // Se busca el IdAsociado, como props.
      async getSpecificCooAsociados(IdAsociado) {
        try {
          const response = await getSpecificCooAsociados(IdAsociado);
          let asociado = `${response.content['Numero']} - ${response.content['Nombres']} ${response.content['Apellidos']} (${response.content['NumeroDocumento']})`;
          this.NombreAsociado = asociado;
          this.$emit('return-resultado', {IdAsociado: IdAsociado, Numero:response.content.Numero});

        } catch (error) {
          this.addNotification(
            'error',
            'Error al obtener el asociado',
            error.message
          );
        }
      },
      limpiarInput(){
        this.NombreAsociado = null;
      },
      addNotification(type, title, text) {
        this.$toast.add({
          severity: type,
          summary: title,
          detail: text,
          life: 3000,
        });
      },
    },
    mounted() {
    },
  };
</script>
<style lang="scss">
.p-autocomplete-panel {
  max-height: 250px !important;
}

</style>